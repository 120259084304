export const withCommas = (num) => {
  if (num === null) return;

  return num
    .toString()
    .split('')
    .reverse()
    .map((digit, index) => (index !== 0 && index % 3 === 0 ? `${digit},` : digit))
    .reverse()
    .join('');
};
