import React, { useEffect, useRef, useState } from 'react';

import Head from 'next/head';
import moment from 'moment';
import axios from 'axios';
import ReactSwitch from 'react-switch';
import styled from 'styled-components';
import { CardBody, Col, Row, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'next-i18next';

import { getLayout } from 'src/layouts/PanelLayout';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { loadAvailablePackages, loadPackageInfo, loadPaymentMethods } from 'src/actions/app';
import { withCommas } from 'src/utils/number';
import authRequest from 'lib/authRequest';
import {
  ACTIVE_PACKAGE,
  API_PACKAGES_ACTIVATE_STARTER,
  API_PAYMENT_METHOD_LINK,
  API_PAYMENT_STATUS_ORDER,
  INTERNAL_API_SESSION,
  REFUND_POLICY,
} from 'src/const/routes';

import getSymbolFromCurrency from 'currency-symbol-map';

import gearLoadingSvg from 'src/assets/img/gear-loading.svg';
import { Card } from 'src/components/common';
import FacebookPixel from 'src/utils/facebookPixel';
import Loader from 'src/components/common/loader';
import { durationToCountdownFormat } from 'src/utils/date';
import { billedAnnuallyByDefault } from 'src/config.client';

import paypalLogoSvg from 'src/assets/img/paypal-logo.svg';
import coinbaseLogoSvg from 'src/assets/img/coinbase-logo.svg';
import cardLogoSvg from 'src/assets/img/card-logo.svg';
import { trackAddToCard, trackClick } from 'src/api/metrics';
import Link from 'next/dist/client/link';

import Media from 'src/utils/media';
import { BLACK_FRIDAY_NOTIFIER, DEMO_OFFER } from 'src/const/notifiers';
import { BLACK_FRIDAY_FEATURE, WELCOME_BONUS, STAND_WITH_UKRAINE } from 'src/const/features';
import { useRouter } from 'next/router';

const Icon = styled.img`
  display: block;
  height: 42px;
  width: auto;
  margin: 20px auto;
`;

const ProcessingMessage = styled.h2`
  font-size: 18px;
  font-weight: 300;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
`;

const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: #9a9a9a;
`;

const Switch = styled(ReactSwitch)`
  display: flex;
  width: 96px;
`;

const PackageCard = styled(Col)`
  max-width: 380px !important;
  min-width: 200px !important;
`;

const SwitchLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`;

const PackageItem = styled.div`
  min-height: 480px;
  background: #27293b;
`;

const PackageHeader = styled.div`
  background: linear-gradient(30deg, ${(props) => props.fromColor}, 50%, ${(props) => props.toColor});
  padding: 12px 0;
  position: relative;
  box-shadow: ${(props) => props.boxShadow};

  > h5 {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin: auto;
  }
`;

const TopOfferLabel = styled.span`
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translate(-50%, 0);
  background: linear-gradient(120deg, #eab734, #f19540);
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400;

  ${Media.xxxl`
    font-size: 10px;
  `}
`;

const PackageBody = styled.div`
  padding: 24px 32px;

  ${Media.xxxl`
    padding: 16px 12px;
  `}
`;

const Agreement = styled.div`
  display: block;
  font-size: 9px !important;
  font-weight: 300;
  text-align: center;
`;

const ValueLabel = styled.span`
  display: block;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
`;

const CommentLabel = styled.span`
  display: block;
  font-size: 11px;
  font-weight: 300;
  text-align: center;
  color: #e5e5e5;
`;

const Features = styled.div`
  height: 428px;
`;

const Feature = styled.p`
  font-size: 11px;
  color: #e5e5e5;
  font-weight: 400;
  margin: 16px 0;

  > b {
    font-size: 11px;
    font-weight: 700;
    color: #ffffff;
  }

  > i {
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    padding-top: 7px;
    width: 28px;
    height: 28px;
    margin-right: 10px;
    background: linear-gradient(64.25deg, #444658, 15.64%, #75769c 94.68%);
  }

  span {
    float: right;
    width: 74%;
    padding-top: 5px;

    ${Media.xxxlMin`
      width: 80%;
    `}
  }
`;

const FullPriceLabel = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: #9a9a9a;
  text-decoration: line-through;
`;

const PriceLabel = styled.span`
  display: block;
  position: relative;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
`;

const SavedLabel = styled.span`
  display: block;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  margin-top: 4px;
  color: #35ea94;
`;

const DiscountLabel = styled.span`
  display: block;
  font-size: 11px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 4px;
  font-weight: 300;
`;

const PlanDurationLabel = styled(DiscountLabel)`
  margin-bottom: -18px;
`;

const MethodCard = styled(Col)`
  max-width: 380px !important;
`;

const MethodItem = styled.div`
  min-height: 300px;
  background: #27293b;
`;

const MethodHeader = styled.div`
  background: #ffffff;
  padding: 12px 0;
  height: 160px;
  display: flex;

  h5 {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin: auto;
  }

  img {
    display: block;
    width: 280px;
    height: auto;
    max-height: 140px;
    margin: 0 auto;
  }
`;

const MethodBody = styled.div``;

const DetailsLink = styled.a`
  color: #ef68a3;

  &:hover {
    color: #ffffff;
  }
`;

const BuyButton = styled.button`
  width: 100%;
  font-size: 18px;
  border: none;
  padding: 12px 0;
  border-radius: 4px;
  text-align: center;
  background: linear-gradient(30deg, #5db8fb, #35ea94);
  color: #f9faff;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    background: linear-gradient(120deg, #5db8fb, #35ea94);
    color: #f9faff;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.disabled {
    background: linear-gradient(30deg, #75a9d9, #89ddb4);
  }
`;

const BackButtonWrapper = styled.div`
  max-width: 380px !important;
  background: #16171b;
  background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
  border-radius: 4px;
  padding: 1px;
`;

const BackButton = styled.button`
  width: 100%;
  font-size: 14px;
  border: none;
  background-color: #27293d;
  color: #f9faff;
  font-weight: 300;

  &:hover {
    background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
    color: #f9faff;
  }

  i {
    color: #ffffff;
    font-size: 14px;
    margin-right: 10px;
  }
`;

const geoTypes = ['countries', 'states', 'cities'];
const methodLogoMap = {
  paypal: paypalLogoSvg,
  coinbase: coinbaseLogoSvg,
  stripe: cardLogoSvg,
  card: cardLogoSvg,
};
const PACKAGE_STARTER = 'starter';

const handleMethod = async (packageId, token, method) => {
  const { orderId, status, redirectUrl } = await authRequest(
    API_PAYMENT_METHOD_LINK.replace('{method}', method).replace('{packageId}', packageId),
    token,
    { method: 'POST' }
  );

  // Track click
  trackClick(`method_${method}`);

  // Track payment attempt
  authRequest(API_PAYMENT_STATUS_ORDER.replace('{orderId}', orderId), token).then((order) => trackAddToCard(order));

  try {
    await axios.put(INTERNAL_API_SESSION, { orderId });
  } catch (err) {
    console.error(err.response);
  }

  if (status) {
    window.location.href = redirectUrl;
  } else {
    // TODO notify user about error
    console.error('Something went wrong');
  }
};

const useCountDown = (notifier) => {
  const offer = useSelector((state) => state.user.notifiers[notifier]);
  const [durationState, setDurationState] = useState(null);

  // init demo offer notifier duration
  useEffect(async () => {
    let mounted = true;
    let intervalContainer = undefined;

    if (offer) {
      let duration = offer.duration;

      if (mounted) {
        intervalContainer = setInterval(() => {
          duration = moment.duration(duration.asMilliseconds() - 1000, 'milliseconds');

          if (duration.asMilliseconds() > 0) {
            setDurationState(duration);
          } else {
            setDurationState(null);
            clearInterval(intervalContainer);
            intervalContainer = undefined;
          }
        }, 1000);
      }
    }

    return function cleanup() {
      setDurationState(null);
      clearInterval(intervalContainer);
      intervalContainer = undefined;
      mounted = false;
    };
  }, [offer]);

  return durationState;
};

let starterInterval = undefined;

const useStarterDuration = () => {
  const packageInfo = useSelector((state) => state.app.packageInfo);
  const [starterDuration, setStarterDuration] = useState(null);
  const initialized = useRef(false);

  // init starter plan duration
  useEffect(async () => {
    if (!initialized.current && packageInfo.code === PACKAGE_STARTER && packageInfo.active) {
      initialized.current = true;
      let duration = moment.duration(moment(packageInfo.expiredAt).diff(moment()));

      starterInterval = setInterval(() => {
        duration = moment.duration(duration.asMilliseconds() - 1000, 'milliseconds');

        if (duration.asMilliseconds() > 0) {
          setStarterDuration(duration);
        } else {
          setStarterDuration(null);
          clearInterval(starterInterval);
          starterInterval = undefined;
        }
      }, 1000);
    }

    return function cleanup() {
      setStarterDuration(null);
      clearInterval(starterInterval);
      starterInterval = undefined;
      initialized.current = false;
    };
  }, [packageInfo]);

  return starterDuration;
};

const Prices = ({
  currency,
  billedAnnually,
  price,
  priceWithoutDiscount,
  priceAnnually,
  priceAnnuallyWithoutDiscount,
  feature,
}) => {
  const { t } = useTranslation();
  let fullPrice = null;
  let withDiscount = null;

  if (priceWithoutDiscount) {
    if (price === 0) {
      fullPrice = null;
      withDiscount = null;
    } else {
      fullPrice = billedAnnually ? priceAnnuallyWithoutDiscount : priceWithoutDiscount;
      withDiscount = billedAnnually ? priceAnnually : price;
    }
  } else {
    fullPrice = billedAnnually ? price : null;
    withDiscount = billedAnnually ? priceAnnually : price;
  }

  const saved = billedAnnually
    ? ((fullPrice - withDiscount) * 12).toFixed(2)
    : fullPrice
    ? (fullPrice - withDiscount).toFixed(2)
    : null;

  const currencySymbol = getSymbolFromCurrency(currency);

  return (
    <>
      {fullPrice && (
        <FullPriceLabel>
          {fullPrice}
          {currencySymbol}
        </FullPriceLabel>
      )}
      {!withDiscount && <PriceLabel>{t('pages.panel.billing.free')}</PriceLabel>}
      {withDiscount > 0 && (
        <PriceLabel>
          {withDiscount}
          {currencySymbol}
        </PriceLabel>
      )}
      <CommentLabel>
        {t('pages.panel.billing.perMonth')} {feature === STAND_WITH_UKRAINE && t('pages.panel.billing.forUkraine')}
      </CommentLabel>
      {saved && (
        <SavedLabel>
          {saved}
          {currencySymbol} {t('pages.panel.billing.saved')}
        </SavedLabel>
      )}
    </>
  );
};

const CountdownContainer = styled(Col)`
  position: relative;
  border-radius: 4px;
  background-image: linear-gradient(64.25deg, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
  margin-bottom: 20px;

  label {
    font-size: 24px;
    font-weight: 300;
  }
`;

const CountdownIcon = styled.span`
  display: inline-block;
  position: absolute;
  left: 10px;
  margin-right: 10px;
  font-size: 24px;
  width: auto;
`;

const DemoOfferCountdown = ({ duration, ...rest }) => {
  const { t } = useTranslation();

  return (
    <div {...rest}>
      <DiscountLabel>{t('pages.panel.billing.discountLabel')}:</DiscountLabel>
      <CountdownContainer className="px-4 mb-2 text-center">
        <CountdownIcon>🤑</CountdownIcon>
        <label>{durationToCountdownFormat(duration)}</label>
      </CountdownContainer>
    </div>
  );
};

const BlackFridayCountdown = ({ duration, ...rest }) => {
  const { t } = useTranslation();

  return (
    <div {...rest}>
      <DiscountLabel>{t('pages.panel.billing.discountBlackFridayLabel')}:</DiscountLabel>
      <CountdownContainer className="px-4 mb-2 text-center">
        <CountdownIcon>🐵</CountdownIcon>
        <label>{durationToCountdownFormat(duration)}</label>
      </CountdownContainer>
    </div>
  );
};

export const Packages = ({ setProcess, forMainPage = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [billedAnnually, setBilledAnnually] = useState(billedAnnuallyByDefault);
  const [loading, setLoading] = useState(true);
  const [starterLoading, setStarterLoading] = useState(false);
  const [step, setStep] = useState(1); // 1 - packages step, 2 - payment methods step
  const [pickedPackageId, setPickedPackageId] = useState(null);
  const token = useSelector((state) => state.user.token);
  const packages = useSelector((state) => state.app.packages);
  const { fullName: activePackageName, active } = useSelector((state) => state.app.packageInfo);
  const paymentMethods = useSelector((state) => state.app.paymentMethods);
  const demoOfferDuration = useCountDown(DEMO_OFFER);
  const blackFridayDuration = useCountDown(BLACK_FRIDAY_NOTIFIER);
  const starterDuration = useStarterDuration();

  const findPackage = (id) => {
    return packages.filter((x) => (billedAnnually ? x.idAnnually === id : x.id === id)).shift();
  };

  const handlePickPackage = (packageId) => {
    const pack = findPackage(packageId);
    if (!pack) {
      console.error('Unable to find picked package by id ' + packageId);
    }

    if (pack.code !== PACKAGE_STARTER) {
      trackClick(`package_${pack.code}`);
    }

    if (pack.code === PACKAGE_STARTER) {
      setStarterLoading(true);
      axios
        .post(API_PACKAGES_ACTIVATE_STARTER, {}, { headers: { Authorization: 'Bearer ' + token } })
        .then(({ status }) => {
          if (status === 201) {
            dispatch(loadPackageInfo());
            setTimeout(() => {
              setStarterLoading(false);
            }, 2000);
          } else {
            setStarterLoading(false);
            console.error('Starter package is restricted');
          }
        })
        .catch(console.error);
    } else if (paymentMethods.length === 1) {
      handleBuy(paymentMethods[0], packageId);
    } else {
      setStep(2);
      setPickedPackageId(packageId);
    }
  };
  const handleBuy = (method, packageId) => {
    const pickedPackage = findPackage(packageId);
    if (!pickedPackage) {
      console.error('Unable to find picked package by id ' + packageId);
    }

    const price = billedAnnually ? pickedPackage.subscriptionTrialPriceAnnually : pickedPackage.subscriptionTrialPrice;
    if (!price) {
      console.error('Unable to get package price');
    }

    FacebookPixel.track(FacebookPixel.INITIATE_CHECKOUT, { currency: 'USD', value: price });
    setProcess(true);
    handleMethod(packageId, token, method).catch((e) => {
      console.error('Unable to create order', e);
      window.location.href = '/panel/billing/cancel';
    });
  };
  const handleBack = () => {
    setProcess(false);
    setPickedPackageId(null);
    setStep(1);
  };

  useEffect(() => {
    if (router.isReady) {
      dispatch(loadAvailablePackages(router.query.utm_campaign));
    }
  }, [router.isReady]);

  useEffect(() => {
    if (loading && packages) {
      setLoading(false);
    }
  }, [packages]);

  useEffect(() => {
    // TODO refactor and move user login assert to actions with waitUser function
    if (token) {
      dispatch(loadPackageInfo());
      dispatch(loadPaymentMethods());
    }
  }, [token]);

  return (
    <>
      {!forMainPage && typeof active !== 'undefined' && (
        <Container className="d-flex justify-content-center px-0">
          <Card>
            <Container className="d-flex justify-content-between ">
              <Subtitle className="my-auto">
                {!active && t('pages.panel.billing.planExpired')}
                {active && (
                  <Trans
                    i18nKey="pages.panel.billing.activePackageName"
                    defaults="Current Plan: {{packageName}}"
                    values={{ packageName: activePackageName }}
                  />
                )}
              </Subtitle>
              <Link href={ACTIVE_PACKAGE} passHref={true} shallow={true}>
                <DetailsLink className="my-auto fw-normal">{t('pages.panel.billing.details')}</DetailsLink>
              </Link>
            </Container>
          </Card>
        </Container>
      )}
      {step === 1 && (
        <Row className="justify-content-center align-content-center mb-3">
          {!forMainPage && <Title>{t('pages.panel.billing.mainTitle')}</Title>}
          {!forMainPage && <Subtitle>{t('pages.panel.billing.subtitle')}</Subtitle>}
          <Row className="justify-content-center mb-2 mt-4">
            <Switch
              checked={billedAnnually}
              onChange={setBilledAnnually}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              className="pulsar"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
            />
            <SwitchLabel className="mt-2">
              {billedAnnually ? t('pages.panel.billing.billedAnnually') : t('pages.panel.billing.billedMonthly')}
            </SwitchLabel>
          </Row>
          <Loader isLoading={loading} backgroundSize="10%">
            <Row className="justify-content-center align-content-center mt-5">
              {packages
                .filter((x) => (billedAnnually ? x.priceAnnually : true))
                .sort((a, b) => a.size * a.maxProjects - b.size * b.maxProjects)
                .map(
                  (
                    {
                      id,
                      idAnnually,
                      fullName,
                      code,
                      free,
                      bestOffer,
                      size,
                      timeOnPage,
                      maxHitsPerVisit,
                      maxProjects,
                      bounceRateModifiable,
                      returningVisitorsModifiable,
                      languageModifiable,
                      countriesGeoTargeting,
                      statesGeoTargeting,
                      citiesGeoTargeting,
                      currency,
                      price,
                      priceAnnually,
                      priceWithoutDiscount,
                      priceAnnuallyWithoutDiscount,
                      trafficType = [],
                      behaviour = [],
                      feature,
                    },
                    i,
                    array
                  ) => (
                    <PackageCard key={code} className="gx-1 mb-3 mb-md-5">
                      <PackageItem style={{ transform: `scale(${bestOffer ? '1' : '1'})` }}>
                        <PackageHeader
                          fromColor={
                            price === 0
                              ? '#383553'
                              : i === array.length - 1
                              ? '#e2820c'
                              : `rgba(116,92,212, ${1 - (array.length - 2 - i) / (array.length - 2)})`
                          }
                          toColor={
                            price === 0
                              ? '#c1b3c1'
                              : i === array.length - 1
                              ? '#fcd962'
                              : `rgba(245,31,253,${1 - (array.length - 2 - i) / (array.length - 2)})`
                          }
                          boxShadow={i === array.length - 1 ? '0 0 10px 0 #eab734' : 'none'}
                        >
                          <h5>{fullName.toUpperCase()}</h5>
                          {bestOffer && (
                            <TopOfferLabel>
                              <i className="fa fa-star" /> Most Popular
                            </TopOfferLabel>
                          )}
                        </PackageHeader>
                        <PackageBody>
                          <ValueLabel>{withCommas(Math.floor(size * maxProjects))}</ValueLabel>
                          <CommentLabel>{t('pages.panel.billing.hitsPerMonth')}</CommentLabel>
                          <Features className="my-4">
                            <Feature>
                              <i className="fa fa-rocket" />
                              <span>
                                {maxProjects > 1 && (
                                  <Trans
                                    i18nKey="pages.panel.billing.feature.maxProjects"
                                    defaults="Up to <bold>{{max}} active projects</bold>"
                                    components={{ bold: <b /> }}
                                    values={{ max: maxProjects, hits: withCommas(size) }}
                                  />
                                )}
                                {maxProjects === 1 && (
                                  <Trans
                                    i18nKey="pages.panel.billing.feature.maxProjectsSingular"
                                    defaults="<bold>{{max}} active project</bold>"
                                    components={{ bold: <b /> }}
                                    values={{ max: maxProjects, hits: withCommas(size) }}
                                  />
                                )}
                              </span>
                            </Feature>
                            <Feature>
                              <i className="fa fa-clock" />
                              <span>
                                <Trans
                                  i18nKey="pages.panel.billing.feature.timeOnPage"
                                  defaults="Up to <bold>{{minutes}} minutes</bold> per each page"
                                  components={{ bold: <b /> }}
                                  values={{
                                    minutes: timeOnPage.reduce((max, value) => (max > value ? max : value)) / 60,
                                  }}
                                />
                              </span>
                            </Feature>
                            <Feature>
                              <i className="fa fa-copy" />
                              <span>
                                <Trans
                                  i18nKey="pages.panel.billing.feature.maxHitsPerVisit"
                                  defaults="Up to <bold>{{hits}} pages</bold> per each user session"
                                  components={{ bold: <b /> }}
                                  values={{ hits: maxHitsPerVisit }}
                                />
                              </span>
                            </Feature>
                            {bounceRateModifiable && (
                              <Feature>
                                <i className="fa fa-sign-out-alt" />
                                <span>
                                  <Trans
                                    i18nKey="pages.panel.billing.feature.bounceRateModifiable"
                                    defaults="Adjustable bounce rate"
                                  />
                                </span>
                              </Feature>
                            )}
                            {returningVisitorsModifiable && (
                              <Feature>
                                <i className="fa fa-user-clock" />
                                <span>
                                  <Trans
                                    i18nKey="pages.panel.billing.feature.returningVisitorsModifiable"
                                    defaults="Adjustable returning visitors"
                                  />
                                </span>
                              </Feature>
                            )}
                            {languageModifiable && (
                              <Feature>
                                <i className="fa fa-language" />
                                <span>
                                  <Trans
                                    i18nKey="pages.panel.billing.feature.languageModifiable"
                                    defaults="Adjustable HTTP Accept-Language"
                                  />
                                </span>
                              </Feature>
                            )}
                            {trafficType.length > 0 && (
                              <Feature>
                                <i className="fa fa-project-diagram" />
                                <span>
                                  <Trans i18nKey="pages.panel.billing.feature.trafficType" defaults="Traffic types:" />{' '}
                                  <b>
                                    {trafficType.map((type) => t(`pages.panel.billing.trafficType.${type}`)).join(', ')}
                                  </b>
                                </span>
                              </Feature>
                            )}
                            {behaviour.length > 0 && (
                              <Feature>
                                <i className="fa fa-desktop" />
                                <span>
                                  <Trans i18nKey="pages.panel.billing.feature.behaviour" defaults="Behaviours:" />{' '}
                                  <b>
                                    {behaviour.map((type) => t(`pages.panel.billing.behaviour.${type}`)).join(', ')}
                                  </b>
                                </span>
                              </Feature>
                            )}
                            {countriesGeoTargeting && (
                              <Feature>
                                <i className="fa fa-globe" />
                                <span>
                                  <Trans i18nKey="pages.panel.billing.feature.geo" defaults="Geo targeting:" />{' '}
                                  <b>
                                    {[countriesGeoTargeting, statesGeoTargeting, citiesGeoTargeting]
                                      .filter(Boolean)
                                      .map((available, i) =>
                                        available ? t(`pages.panel.billing.geo.${geoTypes[i]}`) : ''
                                      )
                                      .join(', ')}
                                  </b>
                                </span>
                              </Feature>
                            )}
                            <Feature>
                              <i className="fa fa-headset" />
                              <span>{t(`pages.panel.billing.feature.${free ? 'no-support' : 'support'}`)}</span>
                            </Feature>
                            <Feature>
                              <i className={`fa fa-${free ? 'redo-alt' : 'sync-alt'}`} />
                              <span>
                                {t(
                                  `pages.panel.billing.feature.${free ? 'manualProlongation' : 'automaticProlongation'}`
                                )}
                              </span>
                            </Feature>
                          </Features>
                          <Prices
                            {...{
                              currency,
                              billedAnnually,
                              price,
                              priceWithoutDiscount,
                              priceAnnually,
                              priceAnnuallyWithoutDiscount,
                              feature,
                            }}
                          />
                          {!forMainPage && (
                            <>
                              {feature === WELCOME_BONUS && demoOfferDuration?.asMilliseconds() > 0 && (
                                <DemoOfferCountdown
                                  duration={demoOfferDuration}
                                  className={price > 0 ? 'visible' : 'invisible'}
                                />
                              )}
                              {feature === BLACK_FRIDAY_FEATURE && blackFridayDuration?.asMilliseconds() > 0 && (
                                <BlackFridayCountdown
                                  duration={blackFridayDuration}
                                  className={price > 0 ? 'visible' : 'invisible'}
                                />
                              )}
                              <PlanDurationLabel
                                className={code === PACKAGE_STARTER && starterDuration ? 'visible' : 'invisible'}
                              >
                                {t('pages.panel.billing.planActiveFor')}
                              </PlanDurationLabel>
                              <PlanDurationLabel
                                className={
                                  code === PACKAGE_STARTER && active && !starterDuration ? 'visible' : 'invisible'
                                }
                              >
                                {t('pages.panel.billing.alreadyActivePlan')}:
                              </PlanDurationLabel>
                              <BuyButton
                                onClick={() => handlePickPackage(billedAnnually ? idAnnually : id)}
                                className={`mt-4 ${
                                  code === PACKAGE_STARTER && (starterDuration || active) && 'disabled'
                                }`}
                                disabled={code === PACKAGE_STARTER && (starterDuration || active)}
                              >
                                <Loader isLoading={code === PACKAGE_STARTER && starterLoading}>
                                  {price !== 0
                                    ? t('pages.panel.billing.buy') + ' ' + fullName
                                    : starterDuration
                                    ? durationToCountdownFormat(starterDuration)
                                    : t('pages.panel.billing.getFree')}
                                </Loader>
                              </BuyButton>
                              <Agreement className={`mt-3 ${price > 0 ? 'visible' : 'invisible'}`}>
                                {t('pages.panel.billing.agreement.iAgree')}&nbsp;
                                <a
                                  href={REFUND_POLICY}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ textDecoration: 'underline' }}
                                >
                                  {t('pages.panel.billing.agreement.refundPolicy')}
                                </a>
                              </Agreement>
                            </>
                          )}
                          {forMainPage && (
                            <BuyButton
                              className="mt-4"
                              onClick={(e) => {
                                e.preventDefault();
                                document.querySelector('#form').scrollIntoView();
                              }}
                            >
                              {t('components.landing.index.tryButton')}
                            </BuyButton>
                          )}
                        </PackageBody>
                      </PackageItem>
                    </PackageCard>
                  )
                )}
            </Row>
          </Loader>
        </Row>
      )}
      {step === 2 && (
        <Row className="justify-content-center align-content-center mb-3">
          <Title>{t('pages.panel.billing.paymentMethod.title')}</Title>
          <Subtitle>
            {t('pages.panel.billing.paymentMethod.subtitle').replace('{name}', findPackage(pickedPackageId)?.name)}
          </Subtitle>
          <Row className="justify-content-center align-content-center my-3">
            {paymentMethods.map((method) => (
              <MethodCard key={method} className="gx-5 mb-5">
                <MethodItem>
                  <MethodHeader>
                    <img
                      className="align-self-center"
                      src={methodLogoMap[method]?.src}
                      alt={method}
                      height={7}
                      width={30}
                    />
                  </MethodHeader>
                  <MethodBody className="p-4">
                    <CommentLabel>{t('pages.panel.billing.paymentMethod.' + method + 'Description')}</CommentLabel>
                    <BuyButton onClick={() => handleBuy(method, pickedPackageId)} className="mt-4">
                      {t('pages.panel.billing.continue')}
                    </BuyButton>

                    <Agreement className={'mt-3'}>
                      {t('pages.panel.billing.agreement.iAgree')}&nbsp;
                      <a href={REFUND_POLICY} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
                        {t('pages.panel.billing.agreement.refundPolicy')}
                      </a>
                    </Agreement>
                  </MethodBody>
                </MethodItem>
              </MethodCard>
            ))}
          </Row>
          <Row className="justify-content-center align-content-center mb-5">
            <BackButtonWrapper className="justify-content-center" onClick={() => handleBack()}>
              <BackButton className="btn btn-secondary">
                <i className="fa fa-arrow-circle-left" />
                {t('pages.panel.billing.paymentMethod.back')}
              </BackButton>
            </BackButtonWrapper>
          </Row>
        </Row>
      )}
    </>
  );
};

const Processing = () => {
  const { t } = useTranslation();

  return (
    <Row className="justify-content-center align-content-center" style={{ minHeight: '48vh' }}>
      <Col sm={6} className="align-self-center text-center">
        <Card>
          <CardBody>
            <Icon src={gearLoadingSvg.src} />
            <ProcessingMessage>{t('pages.panel.billing.processing.message')}</ProcessingMessage>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const Billing = () => {
  const { t } = useTranslation();
  const [process, setProcess] = useState(false);

  return (
    <>
      <Head>
        <title>{t('pages.panel.billing.title')}</title>
        <meta name="description" content="Generated by create next app" />
      </Head>
      {process ? <Processing /> : <Packages setProcess={setProcess} />}
    </>
  );
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

Billing.getLayout = getLayout;

export default Billing;
